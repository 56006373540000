import React, { useEffect } from 'react'
import Cookie from 'js-cookie'
import { useTranslation } from 'react-i18next'

const SignOut = props => {
    const { t } = useTranslation()

    useEffect(() => {
        Cookie.remove('auth')
        Cookie.remove('user')
    }, [])

    return <span>{t('Signed out')}</span>
}

export default SignOut
